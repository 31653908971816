import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import ProfileCard from "../../Profile/ProfileCard";
import {
  Grid,
  Typography,
  Box,
  Stack,
  IconButton,
  TextField,
} from "@mui/material";
import { Print } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { orderBy } from "lodash";
import { event } from "jquery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Laboratory = () => {
  const location = useLocation();
  const {
    firstName,
    lastName,
    dateOfBirth,
    age,
    gender,
    communityId,
    address,
    labsData,
  } = location.state;
  const [open, setOpen] = React.useState(false);
  const [test, setTest] = React.useState("");
  const [filteredTest, setFilteredTest] = React.useState("");
  const [testSource, setTestSource] = React.useState(["All"]);
  const [labsTestData, setLabsTestData] = React.useState(labsData);
  const [searchParam] = React.useState(["SpecimensName"]);
  const [q, setQ] = React.useState("");

  const handleClickOpen = (event) => {
    setOpen(true);
    setTest(event.target.innerText);
    const filterLabTestData = labsData.filter((lab) => {
      return lab.SpecimensName === event.target.innerText;
    });
    setFilteredTest(filterLabTestData);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleSourceFilter = (event) => {
    setTestSource(event.target.value);
    const filterBySource = labsTestData.filter((lab) => {
      return lab.EncounterSource == testSource;
    });
    console.log(filterBySource);
    setLabsTestData(filterBySource);
  };

  const handleTestFilter = (event) => {
    setTest(event.target.value);
    const filterLabTestData = labsData.filter((lab) => {
      return lab.SpecimensName === test;
    });
    setFilteredTest(filterLabTestData);
  };

  const search = (tests) => {
    return tests.filter((item) => {
      /*
 // in here we check if our region is equal to our c state
 // if it's equal to then only return the items that match
 // if not return All the countries
 */
      if (item.EncounterSource == testSource) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (testSource == "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <ProfileCard
        fname={firstName}
        lname={lastName}
        dob={dateOfBirth}
        age={age}
        gender={gender}
        communityId={communityId}
        address={address}
      />
      <Box
        sx={{
          width: "90%",
          maxWidth: "auto",
          marginTop: "10px",
          bgcolor: "background.paper",
          padding: 2,
          boxShadow: 1,
        }}
      >
        <TextField
          size="small"
          style={{ width: "auto", marginRight: "10px" }}
          id="outlined-name"
          label="Name"
          value={q}
          onChange={(e) => setQ(e.target.value)}
        />

        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">
            Filter by Source
          </InputLabel>
          <Select
            style={{ width: "200px", marginRight: "10px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={testSource}
            label="Select a Test"
            onChange={(event) => setTestSource(event.target.value)}
          >
            <MenuItem value="All">All Sources</MenuItem>
            {labsData.map((labs) => {
              return (
                <MenuItem value={labs.EncounterSource}>
                  {labs.EncounterSource}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Filter by Date</InputLabel>
          <Select
            style={{ width: "200px", marginRight: "10px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={test}
            label="Select a Test"
            onChange={handleTestFilter}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value={""}>Last 1 year</MenuItem>
            <MenuItem value={""}>Last 6 months</MenuItem>
            <MenuItem value={""}>Last 1 month</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel id="demo-simple-select-label">Sort By</InputLabel>

          <Select
            style={{ width: "150px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={test}
            label="Select a Test"
            onChange={handleTestFilter}
          >
            <MenuItem value={""}>Date</MenuItem>
            <MenuItem value={""}>Name</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {search(labsTestData).map((lab) => {
        return (
          <Grid item padding={1}>
            <Box
              sx={{
                width: "90%",
                maxWidth: "auto",
                bgcolor: "#E5E4E2",
                padding: 2,
                boxShadow: 2,
                borderRadius: 2,
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                      <Stack direction="row" spacing={1}>
                        <Link
                          style={{ textDecoration: "none" }}
                          state={location.state}
                          onClick={handleClickOpen}
                        >
                          <Typography
                            gutterBottom
                            variant="body1"
                            color="#3F51B5"
                            fontWeight="bold"
                          >
                            {lab.SpecimensName}
                          </Typography>
                        </Link>
                      </Stack>
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom variant="body2">
                        Encounter Type:
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography gutterBottom variant="body2">
                          {lab.Encountertype}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom variant="body2">
                        Source:
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography gutterBottom variant="body2">
                          {lab.EncounterSource}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom variant="body2">
                        Ordered by:
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography gutterBottom variant="body2">
                          {lab.ServiceReferringDoctor}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs>
                      <Typography gutterBottom variant="body2">
                        Reported on:
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Typography gutterBottom variant="body2">
                          {lab.Resultedon}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body2">
                    Actions
                    <Stack direction="row" spacing={2}>
                      <IconButton>
                        <Print />
                      </IconButton>
                    </Stack>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        );
      })}
      <Dialog
        style={{ margin: "20px" }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" sx={{ margin: 1 }}>
            {" "}
            Laboratory : {test}
          </Typography>
        </Toolbar>
        <Divider />
        <ProfileCard
          fname={firstName}
          lname={lastName}
          dob={dateOfBirth}
          age={age}
          gender={gender}
          communityId={communityId}
          address={address}
        />

        {/* <Divider /> */}
        {/* <Box sx={{ margin: 2, width: "70%", minWidth: "300px" }}>
          <FormControl >
            <InputLabel id="demo-simple-select-label">Select Test</InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={test}
              label="Select a Test"
              onChange={handleTestFilter}
            >
              {labsData.map((lab) => {
                return (
                  <MenuItem value={lab.SpecimensName}>
                    {" "}
                    {lab.SpecimensName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box> */}

        <Box>
          <Grid container direction="row" spacing={1} padding={2}>
            <Grid
              sx={{
                width: "30%",
                border: 1,
                borderColor: "lightgrey",
                boxShadow: 3,
              }}
              item
            >
              <Typography
                sx={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                }}
                variant="body1"
              >
                ENCOUNTER SUMMARY
              </Typography>
              <TableContainer>
                {filteredTest &&
                  filteredTest.map((labs) => (
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {" "}
                            Source{" "}
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterSource}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Encounter Type
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterType}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Service
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterService}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Admission Date
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterAdmissionDate}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Discharge Date
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterDischargedate}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Discharge Deposition
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterDischargeDisposition}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
              </TableContainer>
              <Typography
                sx={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                  marginTop: "15px",
                }}
                variant="body1"
              >
                SERVICE PROVIDERS
              </Typography>
              <TableContainer>
                {filteredTest &&
                  filteredTest.map((labs) => (
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {" "}
                            Admitting Provider{" "}
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceAdmittingProvider}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Attending Provider
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceAttendingProvider}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Ordering Provider
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceorderingProvider}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Admission Date
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterAdmissionDate}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Refering Doctor
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceReferringDoctor}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Copy To
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceCopyTo}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Entered By
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceEnteredBy}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Principal Result Interpreter
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServicePrincipleResultInterpreter}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Collected By Provider
                          </TableCell>
                          <TableCell align="left">
                            {labs.ServiceCollectedByProvider}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Primary Care Provider
                          </TableCell>
                          <TableCell align="left">
                            {labs.PrimaryCareProvider}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
              </TableContainer>
              <Typography
                sx={{
                  backgroundColor: "lightgray",
                  padding: "10px",
                  marginTop: "15px",
                }}
                variant="body1"
              >
                SOURCE INFORMATION
              </Typography>
              <TableContainer>
                {filteredTest &&
                  filteredTest.map((labs) => (
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {" "}
                            Source{" "}
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterSource}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          // key={}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            Recieved On
                          </TableCell>
                          <TableCell align="left">
                            {labs.EncounterType}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ))}
              </TableContainer>
            </Grid>

            <Grid sx={{ width: "70%" }} item>
              <Grid
                sx={{
                  padding: "10px",
                  border: 1,
                  borderColor: "lightgrey",
                  boxShadow: 3,
                  marginBottom: "10px",
                }}
                item
              >
                <Typography
                  sx={{
                    backgroundColor: "lightgray",
                    padding: "10px",
                  }}
                  variant="body1"
                >
                  ORDER DETAILS
                </Typography>
                <TableContainer>
                  {filteredTest &&
                    filteredTest.map((labs) => (
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow
                            // key={}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              Order Type
                            </TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Order Number</TableCell>
                            <TableCell align="left">Order Priority</TableCell>
                            <TableCell align="left">Accession Number</TableCell>
                            <TableCell align="left">Collected on</TableCell>
                            <TableCell align="left">Reported On</TableCell>
                          </TableRow>
                          <TableRow
                            // key={}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">{labs.OrderFlag}</TableCell>
                            <TableCell align="left">
                              {labs.OrderStatus}
                            </TableCell>
                            <TableCell align="left">
                              {labs.OrderNumber}
                            </TableCell>
                            <TableCell align="left">
                              {labs.OrderPriority}
                            </TableCell>
                            <TableCell align="left">
                              {labs.OrderAccessionNumber}
                            </TableCell>
                            <TableCell align="left">
                              {labs.OrderCollectedon}
                            </TableCell>
                            <TableCell align="left">
                              {labs.OrderReportedon}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    ))}
                </TableContainer>
                <Divider />
                <Typography sx={{ padding: "20px" }}>Notes: </Typography>
                <Typography sx={{ paddingX: "20px" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.{" "}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  border: 1,
                  borderColor: "lightgrey",
                  boxShadow: 3,
                  marginBottom: "10px",
                }}
                item
              >
                <Typography
                  sx={{
                    backgroundColor: "lightgray",
                    padding: "10px",
                  }}
                  variant="body1"
                >
                  {test.toUpperCase()}
                </Typography>
                <TableContainer>
                  {filteredTest &&
                    filteredTest.map((labs) => (
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow
                            // key={}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              Flag
                            </TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Value</TableCell>
                            <TableCell align="left">Range</TableCell>
                            <TableCell align="left">Unit</TableCell>
                            <TableCell align="left">Source</TableCell>
                            <TableCell align="left">Resulted On</TableCell>
                            <TableCell align="left">Recieved On</TableCell>
                          </TableRow>
                          <TableRow
                            // key={}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">{labs.OrderFlag}</TableCell>
                            <TableCell align="left">
                              {labs.OrderStatus}
                            </TableCell>
                            <TableCell align="left">{labs.Value}</TableCell>
                            <TableCell align="left">{labs.Range}</TableCell>
                            <TableCell align="left">{labs.Units}</TableCell>
                            <TableCell align="left">{labs.Source__1}</TableCell>
                            <TableCell align="left">
                              {labs.Receivedon}
                            </TableCell>
                            <TableCell align="left">
                              {labs.Resultedon}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    ))}
                </TableContainer>
              </Grid>
              <Grid
                sx={{
                  border: 1,
                  borderColor: "lightgrey",
                  boxShadow: 3,
                  marginBottom: "10px",
                }}
                item
              >
                <Typography
                  sx={{
                    backgroundColor: "lightgray",
                    padding: "10px",
                  }}
                  variant="body1"
                >
                  SPECIMENS
                </Typography>
                <TableContainer>
                  {filteredTest &&
                    filteredTest.map((labs) => (
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow
                            // key={}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Method</TableCell>
                            <TableCell align="left">Range</TableCell>
                            <TableCell align="left">Volume</TableCell>
                            <TableCell align="left">Units</TableCell>
                            <TableCell align="left">Body Location</TableCell>
                            <TableCell align="left">Action Code</TableCell>
                            <TableCell align="left">
                              Collecting Device
                            </TableCell>
                            <TableCell align="left">Recieved Date</TableCell>
                          </TableRow>
                          <TableRow
                            // key={}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">
                              {labs.SpecimensName}
                            </TableCell>
                            <TableCell align="left">
                              {labs.SpecimensMethod}
                            </TableCell>
                            <TableCell align="left">{labs.Value}</TableCell>
                            <TableCell align="left">{labs.Range}</TableCell>
                            <TableCell align="left">
                              {labs.SpecimensUnits}
                            </TableCell>
                            <TableCell align="left">
                              {labs.SpecimensBodyLocation}
                            </TableCell>
                            <TableCell align="left">
                              {labs.SpecimensActionCode}
                            </TableCell>
                            <TableCell align="left">
                              {labs.SpecimensCollectingDevice}
                            </TableCell>
                            <TableCell align="left">
                              {labs.Receivedon}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    ))}
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
};

export default Laboratory;
