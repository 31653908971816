import React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Badge, Card, CardActionArea, CardContent } from "@mui/material";
import {
  FileCopySharp,
  History,
  Image,
  NetworkCell,
  NotesOutlined,
} from "@material-ui/icons";
import { Download } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import Laboratory from "./Laboratory/Laboratory";
import ProfileCard from "../Profile/ProfileCard";

const DetailledViewPage = () => {
  const location = useLocation();
  const {
    firstName,
    lastName,
    dateOfBirth,
    age,
    gender,
    communityId,
    address,
    labsData,
  } = location.state;

  return (
    <div style={{ marginTop: "80px" }}>
      <ProfileCard
        fname={firstName}
        lname={lastName}
        dob={dateOfBirth}
        age={age}
        gender={gender}
        communityId={communityId}
        address={address}
      />

      <Box sx={{ my: 1 }}>
        <Grid container>
          <Card
            sx={{ my: 2, maxWidth: "200px", backgroundColor: "lightgrey" }}
            size="medium"
          >
            <CardActionArea sx={{ py: 1, px: 2 }}>
              <Typography textAlign="center">
                <NetworkCell sx={{ mr: 1 }} />
              </Typography>
              <Typography textAlign="center" fontSize={15}>
                National Networks
              </Typography>
            </CardActionArea>
          </Card>
          <Divider variant="middle" />
          <Card
            sx={{ my: 2, maxWidth: "200px", backgroundColor: "lightgrey" }}
            size="medium"
          >
            <CardActionArea sx={{ py: 1, px: 2 }}>
              <Typography textAlign="center">
                <Image sx={{ mr: 1 }} />
              </Typography>
              <Typography textAlign="center" fontSize={15}>
                Image Exchange
              </Typography>
            </CardActionArea>
          </Card>
          <Divider variant="middle" />
          <Card
            sx={{ my: 2, maxWidth: "200px", backgroundColor: "lightgrey" }}
            size="medium"
          >
            <CardActionArea sx={{ py: 1, px: 2 }}>
              <Typography textAlign="center">
                <FileCopySharp sx={{ mr: 1 }} />
              </Typography>
              <Typography textAlign="center" fontSize={15}>
                RHIO Repository
              </Typography>
            </CardActionArea>
          </Card>
          <Divider variant="middle" />
          <Card
            sx={{ my: 2, maxWidth: "200px", backgroundColor: "lightgrey" }}
            size="medium"
          >
            <CardActionArea sx={{ py: 1, px: 2 }}>
              <Typography textAlign="center">
                <Download sx={{ mr: 1 }} />
              </Typography>
              <Typography textAlign="center" fontSize={15}>
                Print/Download Reports
              </Typography>
            </CardActionArea>
          </Card>
          <Divider variant="middle" />
          <Card
            sx={{ my: 2, maxWidth: "200px", backgroundColor: "lightgrey" }}
            size="medium"
          >
            <CardActionArea sx={{ py: 1, px: 2 }}>
              <Typography textAlign="center">
                <NotesOutlined sx={{ marginRight: 2 }} />
              </Typography>
              <Typography textAlign="center" fontSize={15}>
                Generate CCDA
              </Typography>
            </CardActionArea>
          </Card>
          <Divider variant="middle" />
          <Card
            sx={{ my: 2, maxWidth: "200px", backgroundColor: "lightgrey" }}
            size="medium"
          >
            <CardActionArea sx={{ py: 1, px: 2 }}>
              <Typography textAlign="center">
                <History sx={{ mx: 1 }} />
              </Typography>
              <Typography textAlign="center" fontSize={15}>
                Consent History
              </Typography>
            </CardActionArea>
          </Card>
          <Divider variant="middle" />
        </Grid>
      </Box>

      <Divider variant="left" />

      <Box sx={{ my: 2 }}>
        <Grid container alignItems="center">
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center" fontSize={15}>
                      Ambulatory Encounters
                    </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:ambulance-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center">
                      {" "}
                      Emergency Encounters{"   "}
                    </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:accident-and-emergency-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center">
                      {" "}
                      Inpatients Encounters{"   "}
                    </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="medical-icon:i-inpatient"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center">
                      {" "}
                      Other Encounters{" "}
                    </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:inpatient-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/purpose-of-search/communicable-disease-search-service/providerdetails/labs"
                  state={location.state}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography textAlign="center"> Laboratory </Typography>
                      <Typography textAlign="center">
                        <Icon
                          icon="medical-icon:i-laboratory"
                          color="#3f51b5"
                          width="80"
                          height="100"
                        />
                      </Typography>
                      <Typography
                        color="text.secondary"
                        textAlign="center"
                        fontSize={12}
                      >
                        Last updated : 12/20/2022 02:00:12
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Imaging </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="medical-icon:i-imaging-root-category"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Other Orders </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="fontisto:test-tube-alt"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Diagnostics </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="fluent-mdl2:test-suite"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Procedures </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="la:procedures"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Cardiology </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:heart-cardiogram-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center">
                      {" "}
                      Clinical Reports{" "}
                    </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:clinical-f-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center">
                      {" "}
                      Operation Notes{" "}
                    </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:clinical-fe-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Medications </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="game-icons:medicines"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Allergies </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="healthicons:virus-patient-outline"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Vitals </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="ant-design:fund-projection-screen-outlined"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Immune Health </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="game-icons:love-injection"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Social History </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="maki:alcohol-shop"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
          <Grid item padding={1}>
            <Badge color="primary" badgeContent="1">
              <Card sx={{ maxWidth: "200px", padding: "auto" }}>
                <CardActionArea>
                  <CardContent>
                    <Typography textAlign="center"> Claims </Typography>
                    <Typography textAlign="center">
                      <Icon
                        icon="carbon:pedestrian-family"
                        color="#3f51b5"
                        width="80"
                        height="100"
                      />
                    </Typography>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      fontSize={12}
                    >
                      Last updated : 12/20/2022 02:00:12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Badge>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DetailledViewPage;
