import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Search } from "@material-ui/icons";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useNavigate } from "@gatsbyjs/reach-router";
import ProviderData from "../../../public/providerSampleData.json";
import { Link } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';


console.log(ProviderData);

import { MenuItem, Divider, TextField, useTheme } from "@mui/material";
import { isArray } from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CdSearchPage() {
  const [value, setValue] = React.useState(0);
  const [searchResult, setSearchResult] = React.useState(null);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const {
    control,
    register,
    reset,

    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const searchByDemographic = async (values) => {
    const ObjVal = Object.values(values);

    const fname = ObjVal[0];
    const lname = ObjVal[1];
    const birthDate = ObjVal[2];
    const gender = ObjVal[3];
    const finalObj = ProviderData.filter((obj) => {
      if (
        obj.firstName === fname &&
        obj.lastName === lname &&
        obj.gender === gender &&
        obj.dateOfBirth === birthDate
      ) {
        return obj;
      } else {
        return null;
      }
    });
    setSearchResult(finalObj);
  };
  const searchById = async (values) => {
    const { idSrc, idType, id } = values;
    const finalObj = ProviderData.filter((obj) => {
      if (obj.idSrc === idSrc && obj.idType === idType && obj.id === id) {
        return obj;
      } else {
        return null;
      }
    });

    setSearchResult(finalObj);
  };

  const handleFormCancel = () => {
    reset();
    setSearchResult(null);
  };

  const countRecords = () => {
    const numberOfRecords = searchResult.length;
    if (numberOfRecords > 1) {
      return (
        <Link
          to="/purpose-of-search/communicable-disease-search-service/search-results"
          state={searchResult}
        >
          <h2>
            We have found {numberOfRecords} records, That matches your search
            criteria!
          </h2>
        </Link>
      );
    } else if (numberOfRecords === 1) {
      return (
        <Link
          to="/purpose-of-search/communicable-disease-search-service/search-results"
          state={searchResult}
        >
          <h3>
            We have found {numberOfRecords} record, That matches your search
            criteria!
          </h3>
        </Link>
      );
    } else if (numberOfRecords < 1) {
      return <h3>No Records Found, please try again!</h3>;
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/purpose-of-search">
          Purpose of Search
        </Link>

        <Typography color="text.primary">Communicable Disease</Typography>
      </Breadcrumbs>
      <h2>Record Locator Service</h2>
      <Typography sx={{ fontSize: 14 }} color="text.primary">
    
      </Typography>
      <Card sx={{ maxWidth: "90%", minWidth: "300px" }}>
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Demographic Search" {...a11yProps(0)} />
                <Tab label="Identity Search" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: "30px",
                  [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                    flexDirection: "column",
                  },
                  flexDirection: "row",
                }}
              >
                <Box sx={{ display: "flex", flex: "auto" }}>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 0.1, width: "23ch" },
                      display: "inline-flex",
                      gap: "10px",
                      width: "100%",
                      [theme.breakpoints.down(theme.breakpoints.values.tablet)]:
                        {
                          width: "50%",
                        },
                      flexWrap: "wrap",
                    }}
                    onSubmit={handleSubmit(searchByDemographic)}
                  >
                    <Controller
                      name="firstname"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            required
                            style={{ width: "calc(50% - 7.5px)" }}
                            variant="outlined"
                            {...field}
                            label={"First Name"}
                            inputProps={{
                              "aria-label": "first Name Label",
                            }}
                            {...register("firstname", { required: true })}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="lastname"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            required
                            style={{ width: "calc(50% - 7.5px)" }}
                            {...field}
                            label={"Last Name "}
                            inputProps={{
                              "aria-label": "Last Name",
                            }}
                            {...register("lastname", { required: true })}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="birthdate"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            required
                            type="date"
                            style={{ width: "100%" }}
                            {...field}
                            label={"Date of Birth"}
                            inputProps={{
                              "aria-label": "Date of Birth",
                            }}
                            InputLabelProps={{ shrink: true }}
                            {...register("birthdate", { required: true })}
                          />
                        );
                      }}
                    />

                    <Controller
                      name="gender"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            select
                            required
                            style={{ width: "100%" }}
                            {...field}
                            label={"Gender"}
                            inputProps={{
                              "aria-label": "reason",
                            }}
                            {...register("gender", { required: true })}
                          >
                            <MenuItem key={0} value="male">
                              Male
                            </MenuItem>
                            <MenuItem key={1} value="female">
                              Female
                            </MenuItem>
                            <MenuItem key={2} value="other">
                              Other
                            </MenuItem>
                          </TextField>
                        );
                      }}
                    />

                    <Box>
                      <Button
                        disabled={!isValid}
                        title="submit"
                        style={{ minWidth: "180px" }}
                        aria-label="submit"
                        variant="contained"
                        size="large"
                        type="submit"
                        startIcon={<Search />}
                      >
                        <span>Search</span>
                      </Button>

                      <Button
                        style={{ margin: "10px" }}
                        variant="outlined"
                        size="large"
                        onClick={(e) => handleFormCancel(e)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                sx={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: "30px",
                  [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
                    flexDirection: "column",
                  },
                  flexDirection: "row",
                }}
              >
                <Box sx={{ display: "flex", flex: "auto" }}>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 0.1, width: "23ch" },
                      display: "inline-flex",
                      gap: "10px",
                      width: "100%",
                      [theme.breakpoints.down(theme.breakpoints.values.tablet)]:
                        {
                          width: "50%",
                        },
                      flexWrap: "wrap",
                    }}
                    onSubmit={handleSubmit(searchById)}
                  >
                    <Controller
                      name="idType"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            select
                            required
                            style={{ width: "100%" }}
                            {...field}
                            label={"ID Type"}
                            inputProps={{
                              "aria-label": "id type",
                            }}
                            {...register("idType", { required: true })}
                          >
                            <MenuItem key={0} value="medical record number">
                              Medical Record Number
                            </MenuItem>
                            <MenuItem key={1} value="community id">
                              Community ID
                            </MenuItem>
                          </TextField>
                        );
                      }}
                    />
                    <Controller
                      name="idSrc"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            select
                            required
                            style={{ width: "100%" }}
                            {...field}
                            label={"ID Source"}
                            inputProps={{
                              "aria-label": "id source",
                            }}
                            {...register("idSrc", { required: true })}
                          >
                            <MenuItem key={0} value="medical record number">
                              Medical Record Number
                            </MenuItem>
                            <MenuItem key={1} value="community id">
                              Community ID
                            </MenuItem>
                          </TextField>
                        );
                      }}
                    />
                    <Controller
                      name="id"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => {
                        return (
                          <TextField
                            required
                            style={{ width: "100%" }}
                            variant="outlined"
                            {...field}
                            label={"ID"}
                            inputProps={{
                              "aria-label": "ID",
                            }}
                            {...register("id", { required: true })}
                          />
                        );
                      }}
                    />

                    <Box>
                      <Button
                        disabled={!isValid}
                        title="submit"
                        style={{ minWidth: "180px" }}
                        aria-label="submit"
                        variant="contained"
                        size="large"
                        type="submit"
                        startIcon={<Search />}
                      >
                        <span>Search</span>
                      </Button>

                      <Button
                        style={{ margin: "10px" }}
                        variant="outlined"
                        size="large"
                        onClick={(e) => handleFormCancel(e)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </CardContent>
      </Card>

      <Box
        sx={{
          marginTop: "10px",
          maxWidth: "90%",
          minWidth: "300px",
          display: "flex",
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
          justifyContent: "center",
        }}
      >
        {searchResult ? countRecords() : null}
      </Box>
    </div>
  );
}
