import React from "react";
import { Routes, Route, useNavigate, Switch } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./SecureRoute";
import config from "../config";
import Pages from "../components/layout/Pages";
import DetailledViewPage from "../components/pages/DetailledViewPage";
import SearchPage from "../components/pages/SearchPage";
import CdSearchPage from "../components/pages/CdSearchPage";
import { Router } from "@gatsbyjs/reach-router";
import ProviderDetails from "../components/pages/ProviderDetails";
import SearchResultPage from "../components/pages/SearchResultPage";
import Laboratory from "../components/pages/Laboratory/Laboratory";

const oktaAuth = new OktaAuth(config.oidc);

const AppRoutes = () => {
  const history = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  return (
    <>
    <Routes>
      <Route
        path="/"
        element={
          <Pages>
            <LoginCallback />
          </Pages>
        }
      />
      <Route path="login/callback" element={<LoginCallback />} />

      <Route path="/purpose-of-search" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <SearchPage/>
            </Pages>
          }
        />
      </Route>
      <Route path="/purpose-of-search/communicable-disease-search-service" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <CdSearchPage/>
            </Pages>
          }
        />
      </Route>
      <Route path="/purpose-of-search/communicable-disease-search-service/search-results" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <SearchResultPage/>
            </Pages>
          }
        />
      </Route>
      <Route path="/purpose-of-search/communicable-disease-search-service/providerdetails" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <DetailledViewPage/>
            </Pages>
          }
        />
      </Route>
      <Route path="/purpose-of-search/communicable-disease-search-service/providerdetails/labs" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Laboratory/>
            </Pages>
          }
        />
      </Route>
      
    </Routes>
    


    </>
  );
};

export default AppRoutes;
