import React, {useEffect} from "react";
import { useOktaAuth } from "@okta/okta-react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { spacing } from "@mui/system";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { PowerSettingsNew, Search, Settings } from "@material-ui/icons";
import WelcomePage from "../pages/welcome/WelcomePage";
import { HealthAndSafety } from "@mui/icons-material";
import { Link } from "react-router-dom";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar

  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const { window } = props;
  const classes = useStyles();
  const [userInfo, setUserInfo] = React.useState("");
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logout = async () => {
    try {
      await oktaAuth.signOut();
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
      <Link
                to="/purpose-of-search"
                style={{ textDecoration: "none", color: "grey" }}
              >
        <ListItem button key="Purpose of Search">
          <ListItemIcon>
            <Search/>
          </ListItemIcon>
          <ListItemText primary="Purpose of Search" />
        </ListItem>
      </Link>
        <ListItem button key="Provider Details">
          <ListItemIcon>
            <HealthAndSafety />
          </ListItemIcon>
          <ListItemText primary="Provider Details" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key="settings">
          <ListItemIcon>
            <Settings/>
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      {authState.isAuthenticated && (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {/* <img
            src={process.env.PUBLIC_URL + "/CHicon.png"}
            width="35"
            height="35"
         
            alt="logo"
          ></img> */}

          <Typography className={classes.title} variant="h6" noWrap>
          Hypatia Provider Portal
          </Typography>
          <Button
                  variant="contained"
                  id="logout-button"
                  onClick={logout}
                  endIcon={<PowerSettingsNew />}
                >
                  {" "}
                  Logout
                </Button>
        </Toolbar>
      </AppBar>
    )}

{authState.isAuthenticated && (
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
   
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
                      <img
            src={process.env.PUBLIC_URL + "/CHLogo.png"}
            width="180"
            height="55"
            style={{margin:"15px"}}
         
            alt="logo"
          ></img>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      )}
      

      <main className={classes.content}>
            
        {!authState.isPending && !authState.isAuthenticated && (
              <WelcomePage />
            )}
      </main>

    </div>
  );
}

export default ResponsiveDrawer;
