import React, { Component } from 'react';
import DetailledViewPage from './DetailledViewPage';

const ProviderDetails = (props) => {

        return (
            <div style={{marginTop:"100px"}}>
                {console.log(props)}
                <DetailledViewPage {...props} />
            </div>
        );
    }


export default ProviderDetails;