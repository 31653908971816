import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const ProfileCard = (props) => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "auto",
        bgcolor: "background.paper",
        padding: "0px",
      }}
    >
      <Box sx={{ mx: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h5"></Typography>
            <Box sx={{ m: 1 }}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography gutterBottom variant="body2">
                    Name
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      variant="outlined"
                      label={
                        props.fname.toUpperCase() + props.lname.toUpperCase()
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body2">
                    Gender
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip variant="outlined" label={props.gender} />
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body2">
                    Age
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip variant="outlined" label={props.age + " " + "yrs"} />
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body2">
                    DOB
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip variant="outlined" label={props.dob} />
                  </Stack>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="body2">
                    Address
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip variant="outlined" label={props.address} />
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Typography gutterBottom variant="body2">
              Community ID
              <Stack direction="row" spacing={1}>
                <Chip variant="outlined" label={props.communityId} />
              </Stack>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileCard;
