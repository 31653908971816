import React, { Component } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import AirlineSeatFlatIcon from "@mui/icons-material/AirlineSeatFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EmergencyShareIcon from "@mui/icons-material/EmergencyShare";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import { Link } from "react-router-dom";


class SearchPage extends Component {
  render() {
    return (
      <div style={{ marginTop: "80px" }}>
        <Card sx={{ maxWidth: "90%", minWidth: "300px" }}>
          <CardContent>
            <Typography
              variant="h4"
              sx={{ fontSize: 22 }}
              color="text.secondary"
            >
              Please select the purpose of your search.
            </Typography>
          </CardContent>
          <CardActions>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
           
                m: 1,       
              }}
            >
            <Link  style={{ textDecoration: "none" }} to="/purpose-of-search/communicable-disease-search-service">
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<CoronavirusIcon />}
              >
                Communicable Disease
              </Button>
            </Link>
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<InvertColorsIcon />}
              >
                Source of Infection
              </Button>
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<AirlineSeatFlatIcon />}
              >
                Morbidity and Morality
              </Button>
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<VaccinesIcon />}
              >
                Lead Poisoning
              </Button>
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<TrendingUpIcon />}
              >
                Reporting
              </Button>
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<EmergencyShareIcon />}
              >
                Emergency
              </Button>
              <Button
                style={{ margin: "10px" }}
                variant="outlined"
                size="large"
                startIcon={<Diversity1Icon />}
              >
                Medical Examiner
              </Button>
            </Box>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default SearchPage;
