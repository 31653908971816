import React, { Component } from "react";
import DetailledViewPage from "./DetailledViewPage";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ExpandMoreOutlined, Search } from "@material-ui/icons";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Breadcrumbs, Collapse, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isArray } from "lodash";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SearchResultPage = (props) => {
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(false);
  console.log(location.state);
  const providers = location.state;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/purpose-of-search">
          Purpose of Search
        </Link>

        <Link underline="hover" color="inherit"  to="/purpose-of-search/communicable-disease-search-service">Communicable Disease</Link>
        <Typography color="text.primary"> Search Results</Typography>
      </Breadcrumbs>

      {providers
        ? providers.map((obj) => {
            return (
              <Card
                key={obj.uid}
                sx={{
                  width: "90%",
                  maxWidth: 500,
                  bgcolor: "background.paper",
                  padding: "10px",
                  marginTop:"20px"
                }}
              >
                <Box>
                  <Box sx={{ my: 3, mx: 2 }}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography gutterBottom variant="h5" component="div">
                          {obj.firstName.toUpperCase()}{" "}
                          {obj.lastName.toUpperCase()}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography gutterBottom variant="body1">
                          Community ID :
                          <Stack direction="row" spacing={1}>
                            <Chip label={obj.communityId} />
                          </Stack>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider variant="middle" />
                  <Box sx={{ m: 2 }}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography gutterBottom variant="body1">
                          DOB
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <Chip label={obj.dateOfBirth} />
                        </Stack>
                      </Grid>
                      <Grid item xs>
                        <Typography gutterBottom variant="body1">
                          Consent
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <Chip label={obj.consent} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Link
                  to="/purpose-of-search/communicable-disease-search-service/providerdetails"
                  state={obj}
                >
                  {" "}
                  <Button size="small">Learn More</Button>
                </Link>

                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreOutlined />
                </ExpandMore>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography color="text.secondary" variant="body2">
                      {obj.address}
                    </Typography>
                    <Divider variant="left" />

                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography gutterBottom variant="body1">
                          Gender
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <Chip label={obj.gender} />
                        </Stack>
                      </Grid>
                      <Grid item xs>
                        <Typography gutterBottom variant="body1">
                          Age
                        </Typography>
                        <Stack direction="row" spacing={1}>
                          <Chip label={obj.age + " " + "yrs"} />
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            );
          })
        : ""}
    </div>
  );
};

export default SearchResultPage;
